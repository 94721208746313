export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK'
};
// Anything above this should be a special request
export const MAX_EXPORT_DATA_COUNT = 10000;

export const systemRoles = {
  // ioTec id user roles
  ADMIN: 'ADMIN',
  SUPER: 'SUPER',
  BASIC: 'BASIC',
};

export const rootAdminRoles = [
  systemRoles.ADMIN,
  systemRoles.SUPER
];

export const rootUserRoles = [
  ...rootAdminRoles,
  systemRoles.BASIC,
];

export const appPermissions = {
  CUSTOMERS_EDIT: "CUSTOMERS_EDIT",
  CUSTOMERS_VIEW: "CUSTOMERS_VIEW",

  CUSTOMER_GROUP_VIEW: "CUSTOMER_GROUP_VIEW",
  CUSTOMER_GROUP_EDIT: "CUSTOMER_GROUP_EDIT",

  SMS_MESSAGE_TRIGGER: "SMS_MESSAGE_TRIGGER",
  SMS_MESSAGE_VIEW: "SMS_MESSAGE_VIEW",
  SMS_SENDER_ID_EDIT: "SMS_SENDER_ID_EDIT",

  PARTNER_MEMBERS_EDIT: "PARTNER_MEMBERS_EDIT",
  PARTNER_MEMBERS_VIEW: "PARTNER_MEMBERS_VIEW",

  PARTNERS_EDIT: "PARTNERS_EDIT",
  PARTNERS_VIEW: "PARTNERS_VIEW",

  PARTNER_TOPUP: "PARTNER_TOPUP",

  API_KEY_EDIT: "API_KEY_EDIT",

  PARTNER_SETTINGS_VIEW: "PARTNER_SETTINGS_VIEW",
  PARTNER_SETTINGS_EDIT: "PARTNER_SETTINGS_VIEW",

  KANNEL_STATUS_VIEW: "KANNEL_STATUS_VIEW",

};

export const hasAnyRole = (user: any, roles: string[] = []): boolean => {
  const roleData = user.role || user.roles;
  const rolesList = roles.map((it) => it.toLocaleLowerCase());
  if (typeof roleData === 'string') {
    const userRole = roleData.toLocaleLowerCase();
    return rolesList.indexOf(userRole) >= 0;
  }
  const rolesData = roleData?.map((it: any) => it.toLocaleLowerCase()) ?? [];
  return rolesData.some((r: any) => rolesList.indexOf(r) >= 0);
};

export const hasAllRoles = (user: any, roles: string[] = []): boolean => {
  const roleData = user.role || user.roles;
  const rolesList = roles.map((it) => it.toLowerCase());

  if (typeof roleData === 'string') {
    const userRole = roleData.toLowerCase();
    return rolesList.includes(userRole);
  }

  const rolesData = roleData?.map((it: any) => it.toLowerCase()) ?? [];
  return rolesList.every((r: any) => rolesData.includes(r));
};

export const isRootUser = (user: any): boolean => hasAnyRole(user, rootUserRoles)
export const isRootAdminUser = (user: any): boolean => hasAnyRole(user, rootAdminRoles)

export const canEditStaff = (user: any): boolean => hasAnyRole(user, [appPermissions.PARTNER_MEMBERS_EDIT]);
export const canViewPartners = (user: any): boolean => hasAnyRole(user, [appPermissions.PARTNERS_VIEW, appPermissions.PARTNERS_EDIT])
export const canViewContactGroups = (user: any): boolean => hasAnyRole(user, [appPermissions.CUSTOMER_GROUP_VIEW, appPermissions.CUSTOMER_GROUP_EDIT])
export const canEditContactGroups = (user: any): boolean => hasAnyRole(user, [appPermissions.CUSTOMER_GROUP_EDIT])
export const canViewContacts = (user: any): boolean => hasAnyRole(user, [appPermissions.CUSTOMERS_VIEW, appPermissions.CUSTOMERS_EDIT])
export const canSendAndViewMessages = (user: any): boolean => hasAnyRole(user, [appPermissions.SMS_MESSAGE_VIEW, appPermissions.SMS_MESSAGE_TRIGGER])

export const localRoutes = {
  callback: 'callback',
  pending: 'pending',

  dashboard: 'dashboard',

  singleSms: 'sms/single',
  bulkSms: 'sms/bulk',
  bulkSmsHistory: 'history',
  bulkSmsCreate: 'create',
  smsTemplates: 'sms/templates',

  contacts: '/contacts',
  contactsUpload: 'upload',
  contactGroups: '/contact-groups',

  staff: '/staff',

  partners: 'partners',

  settings: 'settings',

  smsKannel: '/kannel-status',

  invoices: 'invoices',

  priceBands: '/price-bands',

};

const servers: any = {
  dev: {
    Pay: 'https://pay-staging.iotec.io',
    Verify: 'https://verify-staging.iotec.io',
    Lumen: 'https://lumen-staging.iotec.io',
    Auth: 'https://id.iotec.io',
    Crm: 'https://iotec-crm-staging.azurewebsites.net',
    Msg: 'https://localhost:7208',
    Msg_: 'https://messaging-staging.iotec.io',

  },
  staging: {
    Pay: 'https://pay-staging.iotec.io',
    Verify: 'https://verify-staging.iotec.io',
    Lumen: 'https://lumen-staging.iotec.io',
    Auth: 'https://id.iotec.io',
    Crm: 'https://iotec-crm-staging.azurewebsites.net',
    Msg: 'https://messaging-staging.iotec.io',
  },
  production: {
    Pay: 'https://pay.iotec.io',
    Verify: 'https://verify.iotec.io',
    Lumen: 'https://lumen.iotec.io',

    Auth: 'https://id.iotec.io',
    Crm: 'https://crm.iotec.io',
    Msg: 'https://messaging-api.iotec.io',
  }
};

const envVar = process.env.REACT_APP_ENV || 'dev';

const environment = envVar.trim();
console.log(`############# Env : ${environment} ################`);

const env = servers[environment];
const authUrl = env.Auth;
const crmUrl = env.Crm;
const msgUrl = env.Msg;
const verifyUrl = env.Verify;
const payUrl = env.Pay;
const lumenUrl = env.Lumen;

console.log(`---------- msgUrl : ${msgUrl} ----------`);

export const remoteRoutes = {
  pay: payUrl,
  verify: verifyUrl,
  lumen: lumenUrl,
  auth: authUrl,
  crm: crmUrl,
  login: `${authUrl}/api/test/login`,
  profile: `${authUrl}/api/test/profile`,
  register: `${authUrl}/api/auth/register`,
  resetApiKey: `${msgUrl}/api/auth/api-key/reset`,
  toggleApiKey: `${msgUrl}/api/auth/api-key/toggle`,
  apiDocumentation: `${msgUrl}/api-docs/index.html`,
  resetPass: `${authUrl}/reset`,

  contacts: `${crmUrl}/api/crm/contact`,
  contactById: `${crmUrl}/api/contact/id`,
  contactsPerson: `${crmUrl}/api/crm/person`,
  contactsCompany: `${crmUrl}/api/crm/company`,
  contactsChc: `${crmUrl}/api/crm/person/chc`,
  contactsEmail: `${crmUrl}/api/crm/email`,
  contactsTag: `${crmUrl}/api/crm/tag`,
  contactsUrl: `${crmUrl}/api/crm/url`,
  contactsPhone: `${crmUrl}/api/crm/phone`,
  contactsAddress: `${crmUrl}/api/crm/address`,
  contactsIdentification: `${crmUrl}/api/crm/identification`,

  contactSearch: `${crmUrl}/api/contact/search`,
  contactsDelete: `${msgUrl}/api/contacts`,

  customerIdentification: `${msgUrl}/api/customers/identification`,
  customerPhone: `${msgUrl}/api/customers/phone`,
  customerEmail: `${msgUrl}/api/customers/email`,
  customerAddress: `${msgUrl}/api/customers/address`,

  userClaims: `${authUrl}/api/User/Claim`,
  userMultiClaims: `${authUrl}/api/multipleClaims`,
  userCustomClaims: `${authUrl}/api/customClaims`,
  userRoles: `${msgUrl}/api/auth/roles`,
  users: `${msgUrl}/api/auth/users`,

  userProfile: `${msgUrl}/api/auth/profile`,

  partners: `${msgUrl}/api/partners`,
  partnersActivateDeactivate: `${msgUrl}/api/partners/activate-deactivate`,
  partnersCombo: `${msgUrl}/api/partners/combo`,
  partnerSettings: `${msgUrl}/api/partner-settings`,

  partnerConfigSimCheck: `${msgUrl}/api/partner-configuration/sim-card`,

  partnerMembers: `${msgUrl}/api/partner-members`,
  partnerMembersActivateDeactivate: `${msgUrl}/api/partner-members/activate-deactivate`,
  partnerMemberInvitation: `${msgUrl}/api/partner-member-invitation`,

  manualTopUp: `${msgUrl}/api/product-subscriptions/manual-top-up`,
  ioTecPayTopUp: `${msgUrl}/api/products/iotec-pay-top-up`,
  topUpPreview: `${msgUrl}/api/product-purchase-orders/preview-top-up`,

  mobileMoneyTopUp: `${msgUrl}/api/product-purchase-orders-iotec-pay/mobile-money/initiate-payment`,
  mobileMoneyPaymentStatus: `${msgUrl}/api/product-purchase-orders-iotec-pay/mobile-money/status`,

  partnerProductCreate: `${msgUrl}/api/products/create`,
  partnerProductCategories: `${msgUrl}/api/products/categories`,

  singleSmsBase: `${msgUrl}/api/sms-message`,
  bulkSmsBase: `${msgUrl}/api/bulk-sms`,
  bulkSmsSendMultiple: `${msgUrl}/api/msg/bulk/send`,
  resendMessages: `${msgUrl}/api/sms-message/reprocess-message`,

  bulkSmsFilePreview: `${msgUrl}/api/bulk-sms/preview-upload`,
  bulkSmsFileSample: `${msgUrl}/api/bulk-sms/sample-file`,
  bulkSmsFileUpload: `${msgUrl}/api/bulk-sms/upload`,
  sendBulkSmsFromTemplate: `${msgUrl}/api/bulk-sms/process-template`,
  bulkSmsUploadHistory: `${msgUrl}/api/bulk-sms/history`,
  bulkSmsReprocessing: `${msgUrl}/api/bulk-sms/resume-bulk-processing`,

  customers: `${msgUrl}/api/customers`,
  addCustomersToGroups: `${msgUrl}/api/customers/add-to-groups`,
  removeCustomersFromGroups: `${msgUrl}/api/customers/remove-from-groups`,

  customersBulkProcess: `${msgUrl}/api/bulk-customers-import/process`,
  customersBulkPreviewUpload: `${msgUrl}/api/bulk-customers-import/preview`,
  customersBulkGetSampleFile: `${msgUrl}/api/bulk-customers-import/sample`,

  customerGroups: `${msgUrl}/api/customer-groups`,
  customerGroupsCombo: `${msgUrl}/api/customer-groups/combo`,

  smsTemplates: `${msgUrl}/api/templates`,
  smsTemplatesCombo: `${msgUrl}/api/templates/combo`,

  messageRecipients: `${msgUrl}/api/customers/message-recipients`,

  productSubscriptions: `${msgUrl}/api/product-subscriptions`,
  subscriptionCreditsUsage: `${msgUrl}/api/product-subscriptions/credits-usage`,

  productPriceBands: `${msgUrl}/api/product-price-bands`,

  productPurchaseOrders: `${msgUrl}/api/product-purchase-orders`,
  productPurchaseOrderInvoices: `${msgUrl}/api/product-purchase-orders-invoices`,
  productPurchaseOrderInvoicesCombo: `${msgUrl}/api/product-purchase-orders-invoices/combo`,

  productPurchaseOrderBankDeposit: `${msgUrl}/api/product-purchase-orders-bank-deposit`,
  productPurchaseOrderBankDepositPay: `${msgUrl}/api/product-purchase-orders-bank-deposit/pay`,

  kannelStatus: `${msgUrl}/api/sms/kannel-status`,

  staticAnalytics: `${msgUrl}/api/messaging-reports/sms-static`,
  dynamicAnalytics: `${msgUrl}/api/messaging-reports/sms-dynamic`,

  docs: `${msgUrl}/api/docs`,
  docsDownload: `${msgUrl}/api/docs/download`,

};
